/* ------------------------------------------------------------ *\
	Shell
\* ------------------------------------------------------------ */

.shell {
	max-width: 768px;
	padding: 0 20px;
	margin: 0 auto;

	&--large {
		max-width: 1180px;
	}

	&--xlarge {
		max-width: 1156px;
	}
	
}
