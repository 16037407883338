/* ------------------------------------------------------------ *\
	Form
\* ------------------------------------------------------------ */

.form {
	font-size: 14px;

	.form {

		&__row {
			margin-bottom: 15px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		&__cols {
			margin: 0 -13px;

			/* Tablet Portrait */
			
			@include breakpoint-tablet-portrait {
				margin: 0 -10px;
			}

			/* Mobile */
			
			@include breakpoint-mobile {
				margin: 0 -5px;
			}

			&:after {
				@include clearfix;
			}
		}

		&__col {
			float: left;
			width: 50%;
			padding: 0 13px;

			/* Tablet Portrait */
			
			@include breakpoint-tablet-portrait {
				padding: 0 10px;
			}

			/* Mobile */
			
			@include breakpoint-mobile {
				padding: 0 5px;
			}

			&:nth-child(odd) {
				clear: both;
			}
			&.error{
				label{
					color: #ff6b6b;
				}
			}
		}

		&__actions {
			padding-top: 16px;

			&:after {
				@include clearfix;
			}

			/* Mobile */
			
			@include breakpoint-mobile {
				padding-top: 25px;
			}
		}

		&__btn {
			float: left;
		}

		&__notice {
			float: right;
		}
	}
}
