/* ------------------------------------------------------------ *\
	List
\* ------------------------------------------------------------ */

[class^="list"] {
	list-style: none outside none;
}

/* ------------------------------------------------------------ *\
	List Logos
\* ------------------------------------------------------------ */

.list-logos {
	margin: 0 -28px 36px;
	font-size: 0;
	text-align: center;

	/* Mobile */
	
	@include breakpoint-mobile {
		margin-left: -15px;
		margin-right: -15px;
	}

	li {
		display: inline-block;
		margin: 6px 28px;
		vertical-align: middle;

		/* Mobile */
		
		@include breakpoint-mobile {
			margin: 15px;
		}
	}

	a {
		transition: opacity .4s;

		&:hover {
			opacity: .8;
		}
	}

	/*  List Logos Small  */
	&--small {
		margin: 0 -37px 0 0;
		text-align: left;

		@media (max-width: 1155px) {
			margin-right: -15px;
		}

		/* Tablet Portrait */
		
		@include breakpoint-tablet-portrait {
			margin: 0 -20px;
		}

		/* Mobile */
		
		@include breakpoint-mobile {
			margin: 0 -5px;
		}

		li {
			margin: 0 37px 0px 0;

			@media (max-width: 1155px) {
				margin-right: 15px;
			}

			/* Tablet Portrait */
			
			@include breakpoint-tablet-portrait {
				margin: 5px 20px;
			}

			/* Mobile */
			
			@include breakpoint-mobile {
				margin: 6px;
			}

			&:nth-child(2) {
				position: relative;
				top: -1px;
				right: -3px;

				/* Tablet Portrait */
				
				@include breakpoint-tablet-portrait {
					top: 0;
					right: 0;
				}
			}
		}
	}

	/*  List Logos Block  */
	&--block {
		margin: 0;
		text-align: left;

		/* Mobile */
		
		@include breakpoint-mobile {
			text-align: center;
		}

		li {
			display: block;
			margin: 0 0 63px 0;

			/* Tablet Portrait */
			
			@include breakpoint-tablet-portrait {
				margin-bottom: 40px;
			}

			/* Mobile */
			
			@include breakpoint-mobile {
				display: inline-block;
				margin: 10px 5px;
			}

			&:last-child {
				margin-bottom: 0;

				/* Mobile */
				
				@include breakpoint-mobile {
					margin-bottom: 10px;
				}
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	List Details
\* ------------------------------------------------------------ */

.list-details {
	max-width: 267px;

	/* Mobile */
	
	@include breakpoint-mobile {
		max-width: none;
	}

	li {
		padding-bottom: 2px;
		border-bottom: 2px solid $blue;
		margin-bottom: -1px;
		font-size: 0;
		text-align: justify;
		-ms-text-justify: distribute-all-lines;
		text-justify: distribute-all-lines;

		&:after {
			display: inline-block;
			width: 100%;
			content: '';
		}
	}

	strong {
		display: inline-block;
		font-size: 33px;
		font-weight: normal;
		vertical-align: bottom;

		sup {
			position: relative;
			top: -12px;
			font-size: 58%;
		}
	}

	span {
		display: inline-block;
		margin-bottom: 5px;
		font-size: 16px;
		vertical-align: bottom;
	}
}

/* ------------------------------------------------------------ *\
	List Features
\* ------------------------------------------------------------ */

.list-features {
	margin-bottom: 48px;
	font-family: $font-graphik;
	font-size: 10px;
	font-weight: 500;
	text-transform: uppercase;

	/* Mobile */
	
	@include breakpoint-mobile {
		margin-right: -10px;
		margin-bottom: 30px;
	}

	li {
		display: inline-block;
		margin: 0 23px 5px 0;
		vertical-align: top;
		text-align: center;

		/* Mobile */
		
		@include breakpoint-mobile {
			margin-right: 10px;
		}
	}

	small {
		display: block;
		font-size: 11px;
	}

	span {
		display: table;
		width: 100%;
		height: 28px;
	}

	strong {
		display: table-cell;
		vertical-align: bottom;
	}

	p {
		margin-top: 7px;
		letter-spacing: -0.02em;
	}
}

/* ------------------------------------------------------------ *\
	List Dashes
\* ------------------------------------------------------------ */

.list-dashes {

	li {
		position: relative;
		padding-left: 10px;
		letter-spacing: -0.01em;

		&:before {
			position: absolute;
			top: 0;
			left: 0;
			content: '\002d';
		}
	}
}
