/* ------------------------------------------------------------ *\
	Tooltip
\* ------------------------------------------------------------ */

.tooltip {
	position: absolute;
	cursor: pointer;

	&:hover {

		.tooltip {

			&__body {
				opacity: 1;
				visibility: visible;
				transform: translateY(0);
			}
		}
	}

	.tooltip {

		&__btn {
			display: inline-block;
			width: 28px;
			height: 28px;
			border-radius: 50%;
			background: $red;
			font-size: 18px;
			line-height: 28px;
			color: $white;
			font-weight: bold;
			text-transform: uppercase;
			text-align: center;
		}

		&__body {
			position: absolute;
			bottom: 100%;
			left: 50%;
			z-index: 3;
			width: 340px;
			padding: 15px;
			border-radius: 5px;
			margin-bottom: 15px;
			margin-left: -170px;
			background: $white;
			opacity: 0;
			visibility: hidden;
			transform: translateY(-15px);
			transition: opacity .4s, visibility .4s, transform .4s;

			/* Mobile */
			
			@include breakpoint-mobile {
				display: none;
			}

			&:after {
				position: absolute;
				left: 50%;
				bottom: -8px;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 8px 8px 0 8px;
				border-color: $white transparent transparent transparent;
				margin-left: -8px;
				content: '';
			}

			h6 {
				margin-bottom: 10px;
			}
		}
	}

	&--1 {
		top: 28.2%;
		right: 34.6%;
	}

	&--2 {
		top: 39.5%;
		right: 24%;
	}

	&--3 {
		top: 43%;
		left: 17.2%;

		.tooltip {

			&__body {

				/* Tablet Portrait */
				
				@include breakpoint-tablet-portrait {
					left: -20px;
					margin-left: 0;
				}

				&:after {
					left: 25px;
					margin-left: 0;
				}
			}
		}
	}

	&--4 {
		top: 12.1%;
		left: 16.3%;

		.tooltip {

			&__body {

				/* Tablet Portrait */
				
				@include breakpoint-tablet-portrait {
					left: -20px;
					margin-left: 0;
				}

				&:after {
					left: 25px;
					margin-left: 0;
				}
			}
		}
	}

	&--5 {
		top: 68.4%;
		right: 25.1%;
	}

	&--6 {
		top: 58.8%;
		right: 14.1%;

		.tooltip {

			&__body {

				/* Tablet Portrait */
				
				@include breakpoint-tablet-portrait {
					left: auto;
					right: -20px;
					margin-left: 0;
				}

				&:after {
					left: auto;
					right: 25px;
					margin-left: 0;
				}
			}
		}
	}

	&--7 {
		top: 25.8%;
		left: 7%;

		.tooltip {

			&__body {

				/* Tablet Portrait */
				
				@include breakpoint-tablet-portrait {
					left: -20px;
					margin-left: 0;
				}

				&:after {
					left: 25px;
					margin-left: 0;
				}
			}
		}
	}
}
