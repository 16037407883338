/* ------------------------------------------------------------ *\
	Main
\* ------------------------------------------------------------ */

.main {
	padding-top: 44px;
	
	&.no-pd-top{
		padding-top: 0px;
	}

	/* Mobile */
	
	@include breakpoint-mobile {
		padding-top: 30px;
	}
}
