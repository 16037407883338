/* ------------------------------------------------------------ *\
	Fullsize Image
\* ------------------------------------------------------------ */

.fullsize-image {
	background-position: 50% 50%;
	background-repeat: no-repeat;
    background-size: cover;

    > img {
    	display: none;
    }
}
