/* ------------------------------------------------------------ *\
	Nav
\* ------------------------------------------------------------ */

.nav {

	/* Tablet Portrait */
	
	@include breakpoint-tablet-portrait {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 3;
		overflow-y: auto;
		width: 100%;
		height: 100%;
		background: $white;
		opacity: 0;
		visibility: hidden;
		transform: scale(.6);
		transition: opacity .4s, visibility .4s, transform .4s;
	}

	&.expanded {

		/* Tablet Portrait */
		
		@include breakpoint-tablet-portrait {
			opacity: 1;
			visibility: visible;
			transform: scale(1);
		}
	}

	.nav {

		&__inner {

			/* Tablet Portrait */
			
			@include breakpoint-tablet-portrait {
				display: table;
				width: 100%;
				height: 100%;
			}
		}
	}

	ul {
		list-style: none outside none;

		/* Tablet Portrait */
		
		@include breakpoint-tablet-portrait {
			display: table-cell;
			padding: 60px 0;
			vertical-align: middle;
		}
	}

	li {
		display: inline-block;
		margin-left: 21px;

		/* Large Desktop */
		
		@include breakpoint-large-desktop {
			margin-left: 8px;
		}

		/* Tablet Portrait */
		
		@include breakpoint-tablet-portrait {
			display: block;
			margin-left: 0;
		}
	}

	a {
		font-weight: 800;
		letter-spacing: -0.009em;
		transition: color .4s;

		&:hover {
			color: $red;
			text-decoration: none;
		}

		/* Tablet Portrait */
		
		@include breakpoint-tablet-portrait {
			display: block;
			padding: 15px 0;
			font-size: 18px;
		}
	}

	.btn-burger {
		display: none;

		/* Tablet Portrait */
		
		@include breakpoint-tablet-portrait {
			display: inline-block;
			top: 30px;
			margin-top: 0;
		}
	}
}
