/* ------------------------------------------------------------ *\
	Fonts
\* ------------------------------------------------------------ */

@font-face {
  font-family: 'Akrobat';
  src: url('../fonts/Akrobat-Black.eot');
  src: url('../fonts/Akrobat-Black.woff2') format('woff2'),
       url('../fonts/Akrobat-Black.woff') format('woff'),
       url('../fonts/Akrobat-Black.eot?#iefix') format('embedded-opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Akrobat';
  src: url('../fonts/Akrobat-Bold.eot');
  src: url('../fonts/Akrobat-Bold.woff2') format('woff2'),
       url('../fonts/Akrobat-Bold.woff') format('woff'),
       url('../fonts/Akrobat-Bold.eot?#iefix') format('embedded-opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Akrobat';
  src: url('../fonts/Akrobat-ExtraBold.eot');
  src: url('../fonts/Akrobat-ExtraBold.woff2') format('woff2'),
       url('../fonts/Akrobat-ExtraBold.woff') format('woff'),
       url('../fonts/Akrobat-ExtraBold.eot?#iefix') format('embedded-opentype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Akrobat';
  src: url('../fonts/Akrobat-Regular.eot');
  src: url('../fonts/Akrobat-Regular.woff2') format('woff2'),
       url('../fonts/Akrobat-Regular.woff') format('woff'),
       url('../fonts/Akrobat-Regular.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('../fonts/Graphik-Medium.eot');
  src: url('../fonts/Graphik-Medium.woff2') format('woff2'),
       url('../fonts/Graphik-Medium.woff') format('woff'),
       url('../fonts/Graphik-Medium.eot?#iefix') format('embedded-opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('../fonts/Graphik-Semibold.eot');
  src: url('../fonts/Graphik-Semibold.woff2') format('woff2'),
       url('../fonts/Graphik-Semibold.woff') format('woff'),
       url('../fonts/Graphik-Semibold.eot?#iefix') format('embedded-opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
    font-family: 'Scratch Design';
    src: url('../fonts/scratch_basic-webfont.eot');
    src: url('../fonts/scratch_basic-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/scratch_basic-webfont.woff2') format('woff2'),
        url('../fonts/scratch_basic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

    @font-face{
        font-family:"Kohinoor W00 Book";
        src:url("../fonts/2089453/dcb9c54b-d0e8-4e6d-804f-f4ba080e719e.woff2") format("woff2"),url("../fonts/2089453/3e2c17d3-53e1-454f-b91d-0865bebf005c.woff") format("woff");
    }
    @font-face{
        font-family:"Kohinoor W00 Bold";
        src:url("../fonts/2169037/b9dffd84-aba3-4ffc-82a3-bddb21880fcb.woff2") format("woff2"),url("../fonts/2169037/3bf40e21-7c6a-4cbc-96e1-e8cc882b6173.woff") format("woff");
    }



