/* ------------------------------------------------------------ *\
	Btn
\* ------------------------------------------------------------ */

.btn {
	display: inline-block;
	min-width: 134px;
	padding: 7px 25px;
	border-radius: 0;
	border: 1px solid $white;
	background: none;
	font-size: 14px;
	box-shadow: none;
	letter-spacing: -0.009em;
	text-transform: none;
	cursor: pointer;
	appearance: none;
	transition: border .4s, background .4s, color .4s, transform .4s;

	&:hover {
		background: $white;
		color: $blue;
	}
	&--blue{
		border: 1px solid $blue;
		background: $blue;
		color: $white;
		&:hover {
			border: 1px solid $white;
			background: $white;
			color: $blue;
		}
	}
}



/*  Btn Primary  */
.btn-primary {
	position: relative;
	display: table;
	min-width: 132px;
	width: auto;
	height: 44px;
	padding: 3px 30px 7px 8px;
	background: url(../images/btn-blue@2x.png) no-repeat 0 0;
	background-size: 100% 100%;
	font-size: 16px;
	line-height: 1.1;
	color: $red;
	font-weight: 800;
	letter-spacing: -0.01em;
	text-align: center;
	transition: opacity .4s;

	&:hover {
		opacity: .7;
		text-decoration: none;
	}

	span {
		display: table-cell;
		vertical-align: middle;
	}

	&:before {
		position: absolute;
		top: 50%;
		right: 10px;
		background: url(../images/btn-blue-arrow@2x.png) no-repeat 0 0;
		width: 17px;
		height: 20px;
		margin-top: -11px;
		background-size: 100% 100%;
		content: '';
	}
}

/*  Btn Burger  */
.btn-burger {
	position: absolute;
    top: 3px;
    right: 2px;
    width: 34px;
	height: 28px;
    z-index: 2;
    transition: all .5s;

	&:before,
	&:after,
    span {
    	height: 2px;
	    width: 100%;
	    left: 0;
	    background: $blue;
	    transform: rotate(0deg);
	    transition: all .5s ease-in-out;
    }

    &:before,
    &:after {
    	position: absolute;
    	content: '';
    }

    &:before {
    	top: 13px;
    }

    &:after {
    	top: 27px;
    }

    span {
    	position: absolute;
	    left: 0;
	    top: 0;
    }

    &.active {

    	&:before {
    		opacity: 0;
    	}

    	&:after {
    		top: 12px;
    		transform: rotate(-45deg);
    	}

    	span {
    		top: 12px;
    		transform: rotate(45deg);
    	}
    }
}
