/* ------------------------------------------------------------ *\
	Table
\* ------------------------------------------------------------ */

.table {
	font-size: 14px;

	th {
		padding: 11px 0 10px 4px;
		font-weight: 800;
		text-align: left;
		letter-spacing: -0.01em;

		/* Mobile */
		
		@include breakpoint-mobile {
			padding-left: 2px;
			padding-top: 0;
		}

		&:first-child {
			width: 50px;
		}

		&:nth-child(2) {
			width: 102px;
		}

		&:nth-child(3) {
			width: 134px;
		}

		&:nth-child(4) {
			width: 59px;
		}
	}

	td {
		padding: 6px 0 6px 4px;
		border-bottom: 1px solid #b3b3b3;

		/* Mobile */
		
		@include breakpoint-mobile {
			padding-left: 2px;
		}
	}

	tr {

		&:last-child {

			td {
				border-bottom-color: transparent;
			}
		}
	}

	/*  Table Secondary  */
	&--secondary {
		th,
		td {
			padding-left: 2px;
		}

		th {

			&:nth-child(3) {
				width: 167px;

				/* Mobile */
				
				@include breakpoint-mobile {
					width: 134px;
				}
			}

			&:nth-child(4) {
				width: 57px;

				/* Mobile */
				
				@include breakpoint-mobile {
					width: 59px;
				}
			}
		}
	}

	/*  Table Small  */
	&--small {
		max-width: 310px;

		/* Mobile */
		
		@include breakpoint-mobile {
			max-width: none;
		}

		th {

			&:first-child {
				width: 102px;
			}

			&:nth-child(2) {
				width: 100px;
			}
		}

		td {
			letter-spacing: -0.01em;
		}
	}
}
