
.litetooltip-hotspot-wrapper{
	max-width: 100% !important;
	.litetooltip-hotspot-container{
		.hotspot{
			background: transparent !important;
			border: none !important;
		}
		img{
			width: 100%;
		}
	}
}
	

.litetooltip-wrapper{
	opacity: 1 !important;
	@include breakpoint-mobile {
		display: none !important;
	}
	.tooltip-arrow{
		border-top-color: #fff !important;
		opacity: 1 !important;
	} .tooltip-content{
		
		
		
		text-align:left !important;
		padding: 15px !important;
		background-color: #fff !important;
		color: $blue !important; 
		opacity: 1 !important;
		box-shadow: none !important;
		font-size: 15px !important;
		
		border-radius: 5px;
		h6{
			margin-bottom: 5px;
		}
	}
}