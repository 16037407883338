/* ------------------------------------------------------------ *\
	Base
\* ------------------------------------------------------------ */

body {
	min-width: 320px;
	background: $grey;
	font-family: $font-akrobat;
	font-size: 18px;
	line-height: 1.25;
	color: $blue;
	text-size-adjust: 100%;

	&.light {
		background: $white;
	}
}

a {
	color: inherit;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}

	&[href^="tel"] {
		
		&:hover,
		&:focus {
			text-decoration: none;
		}
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
	margin-bottom: 15px;
	font-family: $font-akrobat-bold;
	line-height: 1.1;
	font-weight: normal;
	text-transform: uppercase;
}

h1 {
	font-size: 40px;
	line-height: 0.95;
	letter-spacing: -0.01em;

	/* Mobile */
	
	@include breakpoint-mobile {
		font-size: 35px;
	}
}

h2 {
	font-size: 30px;
	line-height: 1.28;

	/* Mobile */
	
	@include breakpoint-mobile {
		font-size: 28px;
	}
}

h3 {
	font-size: 24px;

	/* Mobile */
	
	@include breakpoint-mobile {
		font-size: 26px;
	}
}

h4 {
	font-size: 24px;
}

h5 {
	font-size: 20px;
}

h6 {
	margin-bottom: 15px;
	font-family: $font-akrobat-bold;
	font-size: 18px;
	font-weight: 800;
	text-transform: none;
	letter-spacing: -0.01em;
}

img {
	max-width: 100%;
	height: auto;
}

p {
	margin-bottom: 15px;
	letter-spacing: -0.01em;

	&:last-child {
		margin-bottom: 0;
	}
}

button,
input,
optgroup,
select,
textarea {
  
}

/* Mobile */

@include breakpoint-mobile {
	
}
