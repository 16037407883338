/* ------------------------------------------------------------ *\
	Location
\* ------------------------------------------------------------ */

.location {
	padding-bottom: 47px;
	border-bottom: 1px solid $blue;
	margin-bottom: 51px;

	/* Mobile */
	
	@include breakpoint-mobile {
		padding-bottom: 30px;
		margin-bottom: 30px;
	}

	.location {

		&__content {
			margin-bottom: 22px;
		}

		&__image {
			position: relative;

			&-inner {
				position: absolute;
				top: 0;
				left: 25px;
				width: 124px;
				padding: 15px 5px 12px;
				background: rgba(255, 255, 255, .8);
				text-align: center;
			}
		}
	}
}
