/* ------------------------------------------------------------ *\
	Header
\* ------------------------------------------------------------ */

.header {
	position: relative;
	z-index: 20;
	padding: 36px 33px 43px 36px;
	background: $white;
	font-size: 0;
	text-align: justify;
	-ms-text-justify: distribute-all-lines;
	text-justify: distribute-all-lines;
	  
	
	&--home{
		text-align: right;
	}

	/* Large Desktop */
	
	@include breakpoint-large-desktop {
		padding-left: 15px;
		padding-right: 15px;
	}

	/* Tablet Portrait */
	
	@include breakpoint-tablet-portrait {
		padding: 20px 15px;
	}

	/* Mobile */
	
	@include breakpoint-mobile {
		padding: 15px;
		  min-height: 65px;
	}

	&:after {
	    display: inline-block;
	    width: 100%;
		content: '';
	}

	.logo {
		display: inline-block;
		vertical-align: bottom;
	}

	.nav {
		display: inline-block;
		width: calc(100% - 350px);
		padding-bottom: 3px;
		font-size: 18px;
		vertical-align: bottom;
		text-align: right;

		/* Large Desktop */
		
		@include breakpoint-large-desktop {
			width: calc(100% - 290px);
		}

		/* Tablet Portrait */
		
		@include breakpoint-tablet-portrait {
			display: block;
			width: 100%;
			text-align: center;
		}
	}

	.btn-burger {
		position: absolute;
		top: 50%;
		right: 25px;
		display: none;
		margin-top: -14px;

		/* Tablet Portrait */
		
		@include breakpoint-tablet-portrait {
			display: block;
		}

		/* Mobile */
		
		@include breakpoint-mobile {
			right: 15px;
		}
	}
}
