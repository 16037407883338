/* ------------------------------------------------------------ *\
	Tabs
\* ------------------------------------------------------------ */

.tabs {

	.tab {
		padding-top: 50px;
		&:first-of-type{
			padding-top: 30px;
		}
/*
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		opacity: 0;
		visibility: hidden;
		transition: opacity .4s;

		&.current {
			position: static;
			opacity: 1;
			visibility: visible;
		}
*/
		ul.tab__list{
			    padding-left: 30px;
		    margin-bottom: 30px;
		    li{
			    margin-bottom: 2px;
		    }
		    ul{
			    padding-left: 30px;
			    margin-bottom: 0px;
		    }
		}
	}

	.tabs {

		&__head {
			margin-bottom: 50px;
		}
		&__header{
			position: relative;
			h1{
				color: $white;
				@include center
			}
			&:before{
				content: "";
				width: 100%;
				height: 100%;
				position: absolute;
				left: 0;
				top: 0;
				background-color: black;
				opacity: .2;
			}
		}

		&__body {
			position: relative;
		}

		&__nav {

			ul {
				font-size: 0;
				list-style: none outside none;
			}

			li {
				position: relative;
				display: inline-block;
				padding-right: 11px;
				margin-right: 7px;
				font-size: 18px;
				vertical-align: top;

				&:after {
					position: absolute;
					top: 3px;
					right: 0;
					width: 2px;
					height: 16px;
					border-radius: 3px;
					background: $blue;
					content: '';
				}

				&:last-child {

					&:after {
						display: none;
					}
				}

				&.current {

					a {
						
						&:before {
							left: 0;
							width: 100%;
						}
					}
				}
			}

			a {
				position: relative;
				display: block;
				font-weight: 800;
				letter-spacing: -0.01em;

				&:hover {
					text-decoration: none;
				}

				&:before {
					position: absolute;
					bottom: 0px;
					left: 50%;
					width: 0;
					border-bottom: 2px solid $blue;
					content: '';
					transition: left .4s, width .4s;
				}
			}
		}
	}

	.tab {

		&__image {
			margin: 0 -1px 41px -3px;

			/* Mobile */
			
			@include breakpoint-mobile {
				margin: 0 0 30px;
			}
		}
	}

	/*  Tabs Large  */
	&--large {

		.tabs {

			&__head {
				//margin-bottom: 20px;

				/* Mobile */
				
				@include breakpoint-mobile {
					//margin-bottom: 30px;
				}
			}

			&__nav {

				li {
					padding-right: 10px;
					margin-right: 6px;

/*
					&.current {

						a {
							font-weight: 800;
						}
					}
*/
				}

				a {
					//font-weight: normal;

/*
					&:after {
						overflow: hidden;
						display: block;
						height: 0;
						font-weight: 800;
						visibility: hidden;
						content: attr(title);
					}
*/
				}
			}
		}

		.details {
			padding-bottom: 3px;
			margin-left: -3px;
			margin-right: -1px;

			/* Mobile */
			
			@include breakpoint-mobile {
				padding: 0;
				margin-left: 0;
				margin-right: 0;
			}
		}

		.location {
			padding-top: 29px;

			/* Mobile */
			
			@include breakpoint-mobile {
				padding-top: 0;
			}
		}
	}
}
