/* ------------------------------------------------------------ *\
	Form Elements
\* ------------------------------------------------------------ */

input::placeholder { color: inherit; opacity: 1; }
textarea::placeholder { color: inherit; opacity: 1; }

input:-webkit-autofill { -webkit-text-fill-color: inherit; -webkit-box-shadow: 0 0 0 1000px #fff inset; }

.form__label {
	display: block;
	margin-bottom: 6px;
}

.field {
	display: block;
	width: 100%;
	height: 36px;
	padding: 0 10px;
	border: none;
	background: $white;
	color: $blue;

	&--textarea {
		height: 90px;
		padding: 10px;
	}
}
