/* ------------------------------------------------------------ *\
	Infobox
\* ------------------------------------------------------------ */

.infoBox {

	img {
		display: none !important;
	}
}
