/* ------------------------------------------------------------ *\
	Contacts
\* ------------------------------------------------------------ */

.contacts {

	h6 {
		margin-bottom: 17px;
	}

	.contacts {

		&__content {
			margin-bottom: 27px;
		}

		&__map {
			padding: 0 3px;

			img {
				width: 100%;
			}
		}
	}
}
