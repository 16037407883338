/* ------------------------------------------------------------ *\
	Map
\* ------------------------------------------------------------ */

.map-locations {
	height: 388px;

	/* Mobile */
	
	@include breakpoint-mobile {
		height: 300px;
	}
}

.map-infobox {
	position: relative;
	width: 130px;
	padding: 7px 9px;
	border-radius: 3px;
	margin-left: -65px;
	font-size: 14px;
	line-height: 18px;
	font-weight: 500;
	background: $blue;
	color: $white;
	text-align: center;
	transform: translateY(-130%);

	&:before {
		position: absolute;
		left: 50%;
		bottom: -7px;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 8px 8px 0 8px;
		border-color: $blue transparent transparent transparent;
		margin-left: -8px;
		content: '';
	}

	img {
		display: none !important;
	}
	
	&.map-infobox--0{
		background: $red;
		&:before {
			border-color: $red transparent transparent transparent;
		}
	}
}
