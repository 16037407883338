/* ------------------------------------------------------------ *\
	Logo
\* ------------------------------------------------------------ */

.logo {
	overflow: hidden;
	display: inline-block;
	width: 316px;
	height: 125px;
	background: url(../images/logo@2x.png) no-repeat 0 0;
	background-size: 100% 100%;
	font-size: 0;
	text-indent: 100%;
	white-space: nowrap;

	/* Large Desktop */
	
	@include breakpoint-large-desktop {
		width: 280px;
		height: 111px;
	}

	/* Mobile */
	
	@include breakpoint-mobile {
		width: 220px;
		height: 87px;
	}
}

/* ------------------------------------------------------------ *\
	Logo Waranth
\* ------------------------------------------------------------ */

.logo-waranth {
	overflow: hidden;
	display: inline-block;
	width: 92px;
	height: 103px;
	background: url(../images/logo-waranth@2x.png) no-repeat 0 0;
	background-size: 100% 100%;
	font-size: 0;
	text-indent: 100%;
	white-space: nowrap;
}
