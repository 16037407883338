/* ------------------------------------------------------------ *\
	Footer
\* ------------------------------------------------------------ */

.footer {
	padding: 20px 0 40px;
	background: $white;

	/* Mobile */
	
	@include breakpoint-mobile {
		padding: 24px 0 30px;
	}

	h6 {
		margin-bottom: 1px;
		font-size: 16px;
	}

	p {
		font-size: 16px;
		line-height: 1.2;
		letter-spacing: -0.009em;
	}
	.disclaimer{
		p{
			font-size: 12px;
		}
	}
}

/* ------------------------------------------------------------ *\
	Footer Secondary
\* ------------------------------------------------------------ */

.footer-secondary {
	padding: 22px 0;
	background: $white;

	&:after {
		@include clearfix;
	}

	.footer__inner {
		margin-left: -3px;
		font-size: 0;
		text-align: justify;
		-ms-text-justify: distribute-all-lines;
		text-justify: distribute-all-lines;
		font-size: 0;

		&:after {
			display: inline-block;
		    width: 100%;
			content: '';
		}
	}

	.list-logos {
		display: inline-block;
		vertical-align: middle;

		/* Tablet Portrait */
		
		@include breakpoint-tablet-portrait {
			display: block;
			margin-bottom: 25px;
			text-align: center;
		}

		/* Mobile */
		
		@include breakpoint-mobile {
			margin-bottom: 20px;
		}
	}

	.footer__nav {
		display: inline-block;
		padding: 15px 0;
		margin-right: -2px;
		font-size: 0;	
		vertical-align: middle;

		/* Tablet Portrait */
		
		@include breakpoint-tablet-portrait {
			display: block;
			padding: 0;
			margin-right: 0;
		}

		ul {
			margin-left: -20px;
			list-style: none outside none;
			text-align: left;

			@media (max-width: 1155px) {
				margin-left: -15px;
			}

			/* Tablet Portrait */
			
			@include breakpoint-tablet-portrait {
				text-align: center;
				margin: 0 -10px;
			}

			/* Mobile */
			
			@include breakpoint-mobile {
				margin: 0;
			}
		}

		li {
			display: inline-block;
			margin-left: 20px;
			vertical-align: top;

			@media (max-width: 1155px) {
				margin-left: 15px;
			}

			/* Tablet Portrait */
			
			@include breakpoint-tablet-portrait {
				margin: 5px 10px;
			}

			/* Mobile */
			
			@include breakpoint-mobile {
				display: block;
			}
		}

		a {
			font-size: 16px;
			font-weight: bold;
			letter-spacing: -0.01em;
			transition: color .4s;

			/* Mobile */
			
			@include breakpoint-mobile {
				display: block;
				padding: 5px 0;
				font-size: 18px;
			}

			&:hover {
				color: $red;
				text-decoration: none;
			}
		}
	}

	/*  Footer Secondary Large  */
	&--large {

		.footer__nav {
			float: right;
			padding: 20px 0;
			margin-right: 0;

			/* Tablet Portrait */
			
			@include breakpoint-tablet-portrait {
				float: none;
			}

			/* Mobile */
			
			@include breakpoint-mobile {
				padding: 0;
			}

			ul {
				margin-left: -31px;
				text-align: right;

				/* Tablet Portrait */
				
				@include breakpoint-tablet-portrait {
					margin-left: -10px;
					text-align: center;
				}

				/* Mobile */
				
				@include breakpoint-mobile {
					margin: 0;
				}
			}

			li {
				margin-left: 27px;

				/* Tablet Portrait */
				
				@include breakpoint-tablet-portrait {
					margin-left: 10px;
				}
			}

			a {
				font-size: 18px;
				letter-spacing: 0;
			}
		}
	}
}
