/* ------------------------------------------------------------ *\
	Section
\* ------------------------------------------------------------ */

.section {
	padding: 26px 0;
	border-top: 1px solid $blue;
	margin-bottom: 45px;

	/* Mobile */
	
	@include breakpoint-mobile {
		padding: 30px 0;
		margin-bottom: 0;
	}

	.section {

		&__head {
			padding-top: 8px;
			margin-bottom: 21px;

			/* Mobile */
			
			@include breakpoint-mobile {
				margin-bottom: 30px;
			}

			p {
				padding-top: 9px;

				/* Mobile */
				
				@include breakpoint-mobile {
					padding-top: 0;
				}
			}
		}

		&__title {
			margin-bottom: 0;
		}
	}

	/*  Section Table  */
	&--table {

		.section {

			&__head {
				margin-bottom: 0px;

				/* Mobile */
				
				@include breakpoint-mobile {
					margin-bottom: 30px;
				}
			}

			&__body {
				padding-bottom: 15px;

				/* Mobile */
				
				@include breakpoint-mobile {
					padding-bottom: 0;
				}
			}
		}

		.cols {
			margin: 0 -13px;

			.col {
				padding: 0 13px;

				/* Mobile */
				
				@include breakpoint-mobile {
					margin-bottom: 30px;
				}

				&:last-child {

					/* Mobile */
					
					@include breakpoint-mobile {
						margin-bottom: 0;
					}
				}
			}
		}

		&-secondary {
			padding-bottom: 9px;
			margin-bottom: 9px;

			.section {

				&__head {
					padding-top: 0px;
					margin-bottom: 12px;

					/* Mobile */
					
					@include breakpoint-mobile {
						margin-bottom: 30px;
					}

					p {
						margin-left: 1px;

						/* Mobile */
						
						@include breakpoint-mobile {
							margin-left: 0;
						}
					}
				}

				&__title {
					padding-top: 8px;
				}
			}

			.cols {
				margin: 0 -10px;

				.col {
					padding: 0 10px;
				}
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	Section Primary
\* ------------------------------------------------------------ */

.section-primary {
	margin-bottom: 41px;

	/* Mobile */
	
	@include breakpoint-mobile {
		margin-bottom: 30px;
	}

	.section {

		&__body {
			margin-top: 50px;
			//margin-bottom: 69px;

			/* Mobile */
			
			@include breakpoint-mobile {
				margin-bottom: 30px;
			}

			&:after {
				@include clearfix
			}
		}

		&__content {
			float: left;
			width: 42.7%;
			padding-right: 5px;

			/* Mobile */
			
			@include breakpoint-mobile {
				float: none;
				width: auto;
				padding-right: 0;
				margin-bottom: 30px;
			}

			h1 {
				padding-top: 11px;
				margin-bottom: 9px;
			}

			> span,
			> strong {
				display: block;
				line-height: 1.05;
				color: $red;
			}

			> span {
				font-size: 32px;
				letter-spacing: -0.009em;

				/* Mobile */
				
				@include breakpoint-mobile {
					font-size: 28px;
				}
			}

			> strong {
				font-size: 43px;
				letter-spacing: -0.01em;

				/* Mobile */
				
				@include breakpoint-mobile {
					font-size: 38px;
				}
			}
		}

		&__image {
			overflow: hidden;
			padding-top: 10px;

			/* Mobile */
			
			@include breakpoint-mobile {
				padding-top: 0;
			}

			img {
				width: 100%;
			}
		}
	}

	/*  Section Primary Partners  */
	&--partners {

		.section {

			&__body {
				margin-bottom: 34px;

				/* Mobile */
				
				@include breakpoint-mobile {
					margin-bottom: 30px;
				}
			}

			&__content {
				width: 40.9%;

				/* Mobile */
				
				@include breakpoint-mobile {
					width: auto;
				}
			}
		}
	}
	
	ul{
		    padding-left: 30px;
			margin-bottom: 30px;
	}
}

/* ------------------------------------------------------------ *\
	Section Contacts
\* ------------------------------------------------------------ */

.section-contacts {
	padding: 77px 0 82px;
	background: $blue;
	color: $white;

	/* Mobile */
	
	@include breakpoint-mobile {
		padding: 30px 0 25px;
	}

	h3 {
		font-family: $font-akrobat;
		font-size: 28px;
		line-height: 1.14;
		font-weight: normal;
		text-transform: none;
	}

	.cols {
		margin: 0 -12px;

		/* Tablet Portrait */
		
		@include breakpoint-tablet-portrait {
			margin: 0 -10px;
		}

		.col {
			padding: 0 12px;

			/* Tablet Portrait */
			
			@include breakpoint-tablet-portrait {
				padding: 0 10px;
			}

			/* Mobile */
			
			@include breakpoint-mobile {
				margin-bottom: 30px;
			}

			&:last-child {

				/* Mobile */
				
				@include breakpoint-mobile {
					margin-bottom: 0;
				}
			}
		}
	}

	.section {

		&__body {
			padding-left: 3px;
			margin-right: -3px;

			/* Mobile */
			
			@include breakpoint-mobile {
				padding-left: 0;
				margin-right: 0;
			}
		}

		&__aside {
			padding: 33px 0 0 2px;
			border-top: 2px solid #d4d7dd;
			margin-top: 2px;
			margin-right: 7px;

			/* Tablet Portrait */
			
			@include breakpoint-tablet-portrait {
				margin-right: 0;
			}

			/* Mobile */
			
			@include breakpoint-mobile {
				padding: 30px 0 0;
			}

			.ico-mail {
				margin-bottom: 28px;
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	Section Location
\* ------------------------------------------------------------ */

.section-location {
	margin-bottom: 66px;

	/* Mobile */
	
	@include breakpoint-mobile {
		margin-bottom: 30px;
	}

	> .section {

		&__head {
			padding-top: 11px;
			margin-bottom: 38px;

			/* Mobile */
			
			@include breakpoint-mobile {
				margin-bottom: 30px;
			}

			.section__title {
				margin-bottom: 0;
				line-height: 0.93;
			}
		}
	}

	.section--gallery {
		padding-bottom: 6px;
		margin-bottom: 0;

		/* Mobile */
		
		@include breakpoint-mobile {
			padding-bottom: 0;
		}
	}
}
/* ------------------------------------------------------------ *\
	Section page header
\* ------------------------------------------------------------ */
.section-header{
	height: 300px;
	background-repeat: no-repeat;
	background-size: cover;
	    background-position: center 20%;
	position: relative;
	&__large{
		height: 600px;
	}
	
	> h1, .section-header_content{
		color: #fff;
		text-align: center;
		@include center;
		h1{
			    margin-bottom: 50px;
		}
		h5{
			font-family: $font-akrobat;
		}
		
	}
	@include breakpoint-mobile {
		.section-header_content{
			width: 100%;
			padding: 0 20px;
		}
	}
	
	&__sub-nav{
		padding-top: 20px;
	    text-align: center;
	    position: relative;
/*
		bottom: 10px;
		left: 50%;
		transform: translateX(-50%);
*/
		ul {
			font-size: 0;
			list-style: none outside none;
		}

		li {
			position: relative;
			display: inline-block;
			padding-right: 11px;
			margin-right: 7px;
			font-size: 18px;
			vertical-align: top;

			&:after {
				position: absolute;
				top: 3px;
				right: 0;
				width: 2px;
				height: 16px;
				border-radius: 3px;
				background: $blue;
				content: '';
			}

			&:last-child {

				&:after {
					display: none;
				}
			}

			&.current {

				a {
					
					&:before {
						left: 0;
						width: 100%;
					}
				}
			}
		}

		a {
			position: relative;
			display: block;
			font-weight: 800;
			letter-spacing: -0.01em;
			color: $blue;

			&:hover {
				text-decoration: none;
			}

			&:before {
				position: absolute;
				bottom: 0px;
				left: 50%;
				width: 0;
				border-bottom: 2px solid $blue;
				content: '';
				transition: left .4s, width .4s;
			}
		}
	}
	&:before{
		content: "";
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		background-color: black;
		opacity: .2;
	}
}
/* ------------------------------------------------------------ *\
	Section Column
\* ------------------------------------------------------------ */

	.section-columns{
		padding: 20px 0 40px;
		.section-column{
			width: 33.3333%;
			float: left;
			padding: 0px 5px;
			&__body , &__tail{
				width: 235px;
				margin:auto;
			}
			&__tail{
				margin-top: 30px;
			}
			h3{
				font-family: $font-akrobat;
				font-weight: 800;
				text-align: left;
				display: inline-block;
			}
			p{
				text-align: left;
				display: inline-block;
			}
			a{
				text-align: center;
				text-decoration: none;
				
			}
			@include breakpoint-mobile {
				width: 100%;
				float: none;
				padding: 0 20px;
				
				&__body , &__tail{
					width: 100%;
					max-width: 235px;
					
				}
				+ .section-column{
					margin-top: 30px;
				}
			}
		}
	}
/* ------------------------------------------------------------ *\
	Section Intro
\* ------------------------------------------------------------ */

.section-intro {
	padding-top: 48px;
	padding-bottom: 2px;
	background: #dedede;

	/* Tablet Portrait */
	
	@include breakpoint-tablet-portrait {
		padding-top: 30px;
	}

	/* Mobile */
	
	@include breakpoint-mobile {
		padding-bottom: 0;
	}

	.section {

		&__bar {
			margin-left: -3px;
			margin-bottom: 29px;

			@media (max-width: 1155px) {
				margin-left: 0;
			}

			/* Mobile */
			
			@include breakpoint-mobile {
				margin-bottom: 30px;
			}

			a {
				transition: opacity .4s;

				&:hover {
					opacity: .8;
				}
			}
		}

		&__image {
			width: calc(50vw + 568px);

			img {
				width: 100%;
			}
		}
	}

	/*  Section Intro Primary  */
	&--primary {

		.section {

			&__image {

				@media (max-width: 1176px) {
					width: auto;
					margin: 0 -10px;
				}
			}
		}
	}

	/*  Section Intro Secondary  */
	&--secondary {

		.list-logos--block {
			display: inline-block;
			width: 216px;
			padding-left: 2px;
			padding-bottom: 37px;
			vertical-align: bottom;
			white-space: normal;

			/* Tablet Portrait */
			
			@include breakpoint-tablet-portrait {
				width: 190px;
				padding-left: 0;
			}

			/* Mobile */
			
			@include breakpoint-mobile {
				flex: 0 1 100%;
				display: block;
				width: auto;
				padding-bottom: 0;
				margin-bottom: 0px;
				background: $white;
			}
		}

		.section {

			&__bar {
				margin-bottom: 42px;

				/* Mobile */
				
				@include breakpoint-mobile {
					margin-bottom: 30px;
				}
			}

			&__body {
				font-size: 0;
				white-space: nowrap;

				/* Mobile */
				
				@include breakpoint-mobile {
					display: flex;
					flex-wrap: wrap;
					margin: 0 -10px;
					background: $white;
				}
			}

			&__image {
				display: inline-block;
				width: calc(50vw + 352px);
				vertical-align: bottom;

				@media (max-width: 1155px) {
					width: calc(100% - 206px);
				}

				/* Tablet Portrait */
				
				@include breakpoint-tablet-portrait {
					width: calc(100% - 178px);
				}

				/* Mobile */
				
				@include breakpoint-mobile {
					flex: 0 1 100%;
					order: -1;
					display: block;
					width: auto;
					margin-bottom: 20px;
				}
			}
		}
	}
}
