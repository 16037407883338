/* ------------------------------------------------------------ *\
	Slider
\* ------------------------------------------------------------ */

.slider {
	position: relative;
	
	&__slides-hotspot{
		@include breakpoint-mobile {
			display: none;
		}
	}
	
	&--contained{
		overflow: hidden;
	}

	.slick-slide {
		backface-visibility: visible;
		
		
	}
	
	.slick-slider {
		position: static;
	}

	.slick-arrow {
		position: absolute;
		top: 50%;
		z-index: 10;
		width: 46px;
		height: 46px;
		border: none;
		margin-top: -23px;
		background: url(../images/slider-prev@2x.png) no-repeat center center;
		background-size: 100% 100%;
		font-size: 0;
		text-indent: 100%;
		white-space: nowrap;
		transition: opacity .4s;

		/* Mobile */
		
		@include breakpoint-mobile {
/*
			top: 0;
			height: 0;
			padding-top: 48.5%;
			margin-top: 0;
			background-size: 46px 46px;
*/
		    margin-top: -15px;
		    width: 30px;
		    height: 30px;
		    
		    
		}

		&:hover {
			opacity: .7;
		}
	}

	.slick-prev {
		left: 35px;

		/* Tablet Portrait */
		
		@include breakpoint-tablet-portrait {
			left: 50px;
		}

		/* Mobile */
		
		@include breakpoint-mobile {
			left: 10px;
		}
	}

	.slick-next {
		right: 35px;
		background-image: url(../images/slider-next@2x.png);

		/* Tablet Portrait */
		
		@include breakpoint-tablet-portrait {
			right: 50px;
		}

		/* Mobile */
		
		@include breakpoint-mobile {
			right: 10px;
		}
	}

	.slick-list {
		overflow: visible;
	}

	.slick-initialized{
		.slider__slide{
			&:not(:first-child){
				position: relative;
				opacity: 1;
			}
		}
	}
	.slider {
		

		&__slide {
			position: relative;
			
			&:not(:first-child){
				position: absolute;
				opacity: 0;
			}

			body.old-browser & {

				span {
					display: none;
				}

				.slider__slide-image {

					&:before {
						display: block;
					}
				}
			}

			span {
				position: absolute;
				top: 0;
				left: 0;
				z-index: 3;
				width: 100%;
				height: 100%;
				display: block;
				opacity: .75;
				background-color: $blue;
				background-blend-mode: multiply;
			    background-position: 50% 50%;
			    background-repeat: no-repeat;
			    background-size: cover;
				pointer-events: none;
				transition: opacity .4s;

				/* Mobile */
				
				@include breakpoint-mobile {
					opacity: 0;
				}
			}

			&-image {
				position: relative;

				/* Mobile */
				
				@include breakpoint-mobile {
					//padding-top: 48.5%;
				}

				&:before {
					position: absolute;
					top: 0;
					left: 0;
					z-index: 2;
					width: 100%;
					height: 100%;
					display: none;
					background: #0b213f;
					opacity: .75;
					transition: opacity .4s;
					content: '';

					/* Mobile */
					
					@include breakpoint-mobile {
						opacity: 0;
					}
				}

				img {
					display: block;
					width: 100%;
					opacity: 0;

					/* Mobile */
					
					@include breakpoint-mobile {
						//display: none;
					}
				}
			}

			&-content {
				position: absolute;
				top: 0;
				left: 25px;
				z-index: 1;
				width: 218px;
				padding: 26px 10px 20px 24px;
				background: $white;
				opacity: 0;
				transition: opacity .4s;

				/* Mobile */
				
				@include breakpoint-mobile {
					position: static;
					width: auto;
					padding: 15px;
					opacity: 1;
				}

				h6 {
					margin-bottom: 10px;
					line-height: 1.2;
				}
			}
		}
	}

	.slick-active {

		span {
			opacity: 0;
		}

		.slider__slide-image {

			&:before {
				opacity: 0;
			}
		}

		.slider__slide-content {
			opacity: 1;
		}
	}

	&--wide {
		position: relative;
		left: 50%;
		right: 50%;
		width: 100vw;
		margin-left: -50vw;
		margin-right: -50vw;

		.slider__clip {
			max-width: 700px;
			margin-left: auto;
			margin-right: auto;

			/* Mobile */
			
			@include breakpoint-mobile {
				max-width: none;
			}
		}
	}

	&--small {

		.slider__clip {
			max-width: 426px;
			margin-left: auto;
			margin-right: auto;

			/* Mobile */
			
			@include breakpoint-mobile {
				max-width: none;
			}
		}

		.slick-arrow {
			width: 28px;
			height: 28px;
			margin-top: -14px;

			/* Mobile */
			
			@include breakpoint-mobile {
				width: 46px;
				height: 46px;
				margin-top: 0;
			}
		}

		.slick-prev {
			left: 21px;

			/* Mobile */
			
			@include breakpoint-mobile {
				left: 10px;
			}
		}

		.slick-next {
			right: 21px;

			/* Mobile */
			
			@include breakpoint-mobile {
				right: 10px;
			}
		}
	}
	&-related-titles{
		ul {
			font-size: 0;
			list-style: none outside none;
		}

		li {
			position: relative;
			display: inline-block;
			    padding: 2px 10px 0 0;
			font-size: 18px;
			vertical-align: top;
			    font-weight: 800;
			    cursor: pointer;
			&.is-active{
				
				text-decoration: underline;
			}
		}
		padding-bottom:20px;
	}
}
