/* ------------------------------------------------------------ *\
	Plan
\* ------------------------------------------------------------ */

.plan {
	margin-bottom: 34px;

	.plan {

		&__content {
			margin-bottom: 51px;

			/* Mobile */
			
			@include breakpoint-mobile {
				margin-bottom: 30px;
			}

			h2 {
				margin-bottom: 22px;

				/* Mobile */
				
				@include breakpoint-mobile {
					margin-bottom: 25px;
				}

				span {
					font-family: $font-akrobat;
					text-transform: none;
					letter-spacing: -0.012em;
				}
			}

			p {
				margin-left: 7px;

				/* Mobile */
				
				@include breakpoint-mobile {
					margin-left: 0;
				}
			}

			.list-details {
				padding-top: 20px;
				padding-left: 2px;

				/* Mobile */
				
				@include breakpoint-mobile {
					padding-left: 0;
				}
			}
		}

		&__inner {
			padding: 35px 29px 28px;
			background: $white;
			margin-left: -3px;
			margin-right: -1px;

			/* Mobile */
			
			@include breakpoint-mobile {
				padding: 25px 15px;
				margin: 0;
			}

			.list-features {
				padding-left: 0px;
			}
		}

		&__image {
			padding-right: 18px;
			text-align: center;

			/* Mobile */
			
			@include breakpoint-mobile {
				padding: 0;
			}
		}

		&__actions {
			padding: 57px 2px 0;
			font-size: 0;
			text-align: right;

			/* Mobile */
			
			@include breakpoint-mobile {
				padding: 40px 0 0;
			}

			&-inner {
				display: inline-block;
			}
		}
	}
}
