/* ------------------------------------------------------------ *\
	Helpers
\* ------------------------------------------------------------ */

/*  Clear  */
.clear:after {
	content: ''; 
	line-height: 0; 
	display: table; 
	clear: both; 
}


/*  Notext  */
.notext {
	font-size: 0; 
	line-height: 0; 
	text-indent: -4000px; 
}

/*  Hidden  */

[hidden],
.hidden { 
	display: none !important;
}

/*  Alignleft  */

.alignleft { 
	float: left;
}

/*  Alignright  */

.alignright { 
	float: right;
}

/*  Disabled  */

[disabled],
.disabled { 
	cursor: default;
}

/*  Grid  */

.cols { 
	margin: 0 -10px;
	
	&:after {
		content: ''; 
		line-height: 0; 
		display: table; 
		clear: both; 
	}
}

.col { 
	float: left;
	width: 50%;
	padding: 0 10px;

	/* Mobile */
	
	@include breakpoint-mobile {
		float: none;
		width: auto;
	}

	&--size1 {
		width: 48%;

		/* Mobile */
		
		@include breakpoint-mobile {
			width: auto;
		}
	}

	&--size2 {
		width: 52%;

		/* Mobile */
		
		@include breakpoint-mobile {
			width: auto;
		}
	}

	&--size3 {
		width: 44.4%;

		/* Mobile */
		
		@include breakpoint-mobile {
			width: auto;
		}
	}

	&--size4 {
		width: 55.6%;

		/* Mobile */
		
		@include breakpoint-mobile {
			width: auto;
		}
	}
}
