/* ------------------------------------------------------------ *\
	Icons
\* ------------------------------------------------------------ */

[class^="ico-"] {
	display: inline-block; 
	background-position: 0 0; 
	background-repeat: no-repeat; 
	background-size: 100% 100%; 
}

.ico-mail {
	background-image: url(../images/icons/ico-mail.png);
	width: 45px;
	height: 34px;
}

.ico-play {
	background-image: url(../images/icons/ico-play.png);
	width: 56px;
	height: 56px;
}

@include retina {
	.ico-mail {
		background-image: url(../images/icons/ico-mail@2x.png);
	}

	.ico-play {
		background-image: url(../images/icons/ico-play@2x.png);
	}
}
