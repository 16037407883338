@mixin position ($position: relative, $coordinates: 0 0 0 0) {
    @if type-of($position) == list {
        $coordinates:$position;
        $position:relative;
    }

    $top:nth($coordinates, 1);
    $right:nth($coordinates, 2);
    $bottom:nth($coordinates, 3);
    $left:nth($coordinates, 4);

    position: $position;

    @if $top == auto {
        top:$top;
    }
    @else if not(unitless($top)) {
        top:$top;
    }
    @if $right == auto {
        right:$right;
    }
    @else if not(unitless($right)) {
        right:$right;
    }
    @if $bottom == auto {
        bottom:$bottom;
    }
    @else if not(unitless($bottom)) {
        bottom:$bottom;
    }
    @if $left == auto {
        left:$left;
    }
    @else if not(unitless($left)) {
        left:$left;
    }
}


@mixin center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
}
