/* ------------------------------------------------------------ *\
	Details
\* ------------------------------------------------------------ */

.details {

	.details {

		&__body {
			position: relative;
			margin-bottom: 18px;
		}

		&__foot {
			overflow: hidden;

			/* Mobile */
			
			@include breakpoint-mobile {
				overflow: visible;
			}

			.slider {

				/* Mobile */
				
				@include breakpoint-mobile {
					margin-left: -10px;
					margin-right: -10px;
				}
			}
		}

		&__actions {
			padding-top: 32px;
			margin-left: -4px;
			margin-right: -4px;

			/* Mobile */
			
			@include breakpoint-mobile {
				padding-top: 30px;
				margin-left: 0;
				margin-right: 0;
			}
		}

		&__tooltips {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}
