/* ------------------------------------------------------------ *\
	Video Item
\* ------------------------------------------------------------ */

.video-item {
	position: relative;
	overflow: hidden;

	&:hover {

		span,
		a {
			transform: scale(1.1);
		}
	}

	body.old-browser & {
		a {

			&:after {
				display: block;
			}
		}

		span {
			display: none;
		}
	}

	span {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 3;
		width: 100%;
		height: 100%;
		display: block;
		opacity: .6;
		background-color: $blue;
		background-blend-mode: multiply;
	    background-position: 50% 50%;
	    background-repeat: no-repeat;
	    background-size: cover;
		pointer-events: none;
		transition: transform .6s;
	}

	&:before,
	&:after {
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 4;
		pointer-events: none;
		transform: translate(-50%, -50%);
		content: '';
	}

	&:before {
		width: 56px;
		height: 56px;
		border-radius: 50%;
		border: 2px solid $white;
		margin-top: 7px;
	}

	&:after {
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 13px 0 13px 26px;
		border-color: transparent transparent transparent $white;
		margin: 7px 0 0 3px;
	}

	a {
		overflow: hidden;
		display: block;
		transition: transform .6s;

		&:after {
			position: absolute;
			top: 0;
			left: 0;
			display: none;
			width: 100%;
			height: 100%;
			background: $blue;
			opacity: .7;
			content: '';
		}

		img {
			display: block;
		}
	}

	img {
		width: 100%;
		opacity: 0;
	}
}
